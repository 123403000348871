import styled from "styled-components"

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: ${(props) =>
    !props.isScrolled ? "none" : "0 5px 15px 0 rgba(0, 0, 0, .10)"};
  background-color: ${(props) =>
    !props.isScrolled ? "rgba(255,255,255,.8)" : "rgba(255,255,255,.8)"};
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
  transition: all 0.2s linear;
`

export const Logo = styled.a`
  color: rgb(69, 69, 69);
  font-size: 1, 5rem;
  cursor: pointer;
  z-index: 1000;

  &:hover {
    color: #2f4f4f;
  }
`

export const NavMenu = styled.ul`
  list-style-type: none;

  & li {
    display: inline-block;
  }

  & a {
    display: block;
    padding: 10px 20px;
    margin: 30px 0;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: ${(props) => (!props.isScrolled ? "#2f4f4f;" : "#2f4f4f;")};
    transition: all 0.2s linear;

    &:hover {
      color: #2f4f4f;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`

export const Img = styled.img`
  padding: 10px;
  display: flex;
  align-items: center;
  width: 10;
  height: 125px;
`

export const BurgerMenu = styled.div`
  display: none;
  width: 50px;
  height: 32px;
  cursor: pointer;
  z-index: 1000;

  & > span {
    display: block;
    width: 100%;
    height: 2px;
    position: relative;
    background-color: #fff;
    transition: all 0.3s linear;

    &:first-of-type {
      transform: ${(props) =>
        props.isOpen ? "translateY(14px) rotate(45deg)" : "rotate(0deg)"};
    }

    &:nth-child(2) {
      top: 13px;
      opacity: ${(props) => (props.isOpen ? 0 : 1)};
    }

    &:last-of-type {
      top: 26px;
      transform: ${(props) =>
        props.isOpen ? "translateY(-14px) rotate(-45deg)" : "rotate(0deg)"};
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`

export const Drawer = styled.div`
  height: calc(100vh);
  width: 100vw;
  position: absolute;
  top: ${(props) => (props.isOpen ? "0" : "-100vh")};
  left: 0;
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(30px);

  & > a {
    color: #2f4f4f;
    letter-spacing: 0.06rem;
    font-size: 2rem;
    text-decoration: none;
    margin-bottom: 2rem;
  }
`
