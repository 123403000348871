import axios from "axios"

class API {
  constructor(baseURL) {
    this.baseURL = baseURL
    this.instance = axios.create({
      baseURL: baseURL,
    })
  }
  sendContactRequest = async (contactData) => {
    return this.instance.post(`/contact/askdevelopment`, contactData)
  }
  sendMailWithAttachment = async (contactData) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    return this.instance.post(
      "/contact/askdevelopment/attachments",
      contactData,
      config
    )
  }
}

const api = new API("https://api.onitsoft.com")

export default api
