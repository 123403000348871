import React from "react"
import { CopyRight } from "./footerElements"

const Footer = () => {
  const currentYear = new Date().getFullYear() // Pobieramy aktualny rok dynamicznie

  return (
    <footer>
      <CopyRight>
        <span>Copyright © {currentYear} Onit Soft. All rights reserved.</span>
      </CopyRight>
    </footer>
  )
}

export default Footer
