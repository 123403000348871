import React, { useState } from "react"
import {
  ContactCol,
  Row,
  Coc,
  ContactUs,
  Location,
  UserIcon,
  EnvelopeIcon,
  MapIcon,
} from "./contactElements"
import { useForm, Controller } from "react-hook-form"
import API from "../../api"
const ContactForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },
  })

  const onSubmit = async (data) => {
    data.category = "contact request"

    try {
      await API.sendContactRequest(data) // Use the API instance
      setIsSubmitted(true)
      setShowSuccessMessage(true)
      reset() // Clear the form
      setTimeout(() => setShowSuccessMessage(false), 3000) // Hide the success message after 3 seconds
    } catch (error) {
      console.error("Error sending contact request:", error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <input
            type="text"
            name="name"
            placeholder="Twoje imię i nazwisko"
            {...field}
            {...register("name")}
          />
        )}
      />
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <input
            type="email"
            name="email"
            placeholder="Twój adres e-mail"
            {...field}
            {...register("email")}
          />
        )}
      />
      <Controller
        control={control}
        name="phone"
        render={({ field }) => (
          <input
            type="text"
            name="phone"
            placeholder="Twój numer telefonu"
            {...field}
            {...register("phone")}
          />
        )}
      />
      <Controller
        control={control}
        name="subject"
        render={({ field }) => (
          <input
            type="text"
            name="subject"
            placeholder="Temat Twojej wiadomości"
            {...field}
            {...register("subject")}
          />
        )}
      />
      <Controller
        control={control}
        name="message"
        render={({ field }) => (
          <textarea
            rows="8"
            name="message"
            placeholder="Wiadomość"
            {...field}
            {...register("message")}
          />
        )}
      />
      <button type="submit">Wyślij</button>
      {showSuccessMessage && (
        <p style={{ color: "green", marginTop: "10px" }}>
          Wiadomość została wysłana!
        </p>
      )}
    </form>
  )
}

const Contact = () => {
  return (
    <>
      <ContactUs id="contact">
        <Row>
          <ContactCol>
            <h2>SKONTAKTUJ SIĘ Z NAMI</h2>
            <Coc>
              <UserIcon />
              <span>
                <h5>Robert Matracki</h5>
                <a href="mailto:robert.matracki@askdevelopment.pl">
                  robert.matracki@askdevelopment.pl
                </a>
                <p>
                  <a href="tel:+48606647277">+48 606-647-277</a>
                </p>
              </span>
            </Coc>
            <Coc>
              <UserIcon />
              <span>
                <h5>Zbigniew Kowalski</h5>
                <a href="mailto:zbigniew.kowalski@askdevelopment.pl">
                  zbigniew.kowalski@askdevelopment.pl
                </a>
                <p>
                  <a href="tel:+48600881188">+48 600-881-188</a>
                </p>
              </span>
            </Coc>

            <Coc>
              <EnvelopeIcon />
              <span>
                <h5>Cystersów 13A/1</h5>
                <p>31-553 Kraków, Polska, PL</p>
              </span>
            </Coc>
            <Coc>
              <MapIcon />
              <span>
                <a href="mailto:biuro@askdevelompent.pl">
                  biuro@askdevelompent.pl
                </a>
                <p>Wyślij do nas wiadomość e-mail</p>
              </span>
              <span></span>
            </Coc>
          </ContactCol>
          <ContactCol>
            <ContactForm />
          </ContactCol>
        </Row>
      </ContactUs>

      <Location>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10283.879202010969!2d20.1141754!3d49.8805937!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716152c817bea5d%3A0x7afa20a36039a3aa!2sKrzy%C5%BC%20milenijny%20na%20Skrzynce!5e0!3m2!1spl!2spl!4v1697529401789!5m2!1spl!2spl"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Location>
    </>
  )
}

export default Contact
