export const MenuItems = [
  {
    title: "STRONA GŁÓWNA",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "INWESTYCJA",
    url: "/#about",
    cName: "nav-links",
  },
  {
    title: "KONTAKT",
    url: "/#contact",
    cName: "nav-links",
  },
  {
    title: "STATUS PRAC",
    url: "/#status",
    cName: "nav-links",
  },
  {
    title: "LOKALE",
    url: "/#layout",
    cName: "nav-links",
  },
]
