import styled from "styled-components"

export const Container = styled.div`
  padding: 50px;
  font-family: Arial, sans-serif;

  @media (max-width: 768px) {
    padding: 20px;
  }
`

export const Tabs = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Tab = styled.button`
  flex: 1;
  padding: 10px 15px;
  font-size: 16px;
  background-color: ${(props) => (props.active ? "#2f4f4f" : "#f3f3f3")};
  color: ${(props) => (props.active ? "white" : "#333")};
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: ${(props) => (props.active ? "#064040" : "#ddd")};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 10px;
    margin-bottom: 5px;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ImageSection = styled.div`
  flex: 3;

  img {
    width: 100%;
    border: 2px solid #ddd;
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

export const InfoSection = styled.div`
  flex: 1;
  margin-left: 20px;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

export const Title = styled.h2`
  font-size: 24px;
  color: #2f4f4f;
  text-align: center;
  margin-top: 7rem;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-top: 5rem;
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #2f4f4f;
    color: #fff;
  }

  td {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    th,
    td {
      font-size: 12px;
      padding: 5px;
    }
  }
`

export const FloorPlan = styled.div`
  margin-top: 20px;

  img {
    width: 100%;
    border: 1px solid #ccc;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`

export const DownloadButton = styled.button`
  margin-top: 20px;
  padding: 10px 15px;
  font-size: 14px;
  color: white;
  background-color: #2f4f4f;
  align-items: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    border: 1px solid #2f4f4f;
    color: #2f4f4f;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 10px;
  }
`
