import styled from "styled-components"

import img3 from "../../assets/img3.jpg"
import img5 from "../../assets/img5.jpg"

export const Header = styled.div`
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.2), rgba(4, 9, 30, 0.3)),
    url(${img3});
  background-position: center;
  position: relative;
  background-size: cover;

  @media (max-width: 768px) {
    min-height: 100vh;
  }
`

export const TextBox = styled.div`
  width: 90%;
  color: #fff;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;

  @media (max-width: 768px) {
    top: 50%;
    text-align: center;
    width: 100%;
  }
`

export const HeroContent = styled.div`
  position: absolute;
  max-width: 50rem;
  top: -1rem;

  & h2 {
    color: #2f4f4f;
    text-shadow: 6px -25px 100px rgba(66, 68, 90, 1);
    box-shadow: 1px grey;
    margin-bottom: 20px;
    font-size: 2.5rem;
    line-height: 1;
  }

  & p {
    color: #fff;
    margin-left: 1px;
    letter-spacing: 1px;
    font-weight: lighter;
    font-size: 1.25rem;
  }

  @media (max-width: 768px) {
    margin-bottom: 40px;
    max-width: 100%;
    top: 0;

    & h2 {
      font-size: 1.8rem;
    }

    & p {
      font-size: 0.9rem;
      padding-left: 10px;
    }
  }
`

export const About = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 6rem;

  @media (max-width: 720px) {
    flex-direction: column;
    margin-top: 4rem;
  }
`

export const Container = styled.div`
  padding: 5rem 1rem;
  display: flex;
  width: 100vw;

  @media (max-width: 1024px) {
    padding: 3rem 1rem;
  }
`

export const Photos = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 700px;
  margin: 0 2rem;

  & > div {
    display: flex;
    position: relative;
    left: 30px;
    width: 95%;
    height: 95%;
    margin: 2.5% 2.5%;
    background-image: url(${img5});
    background-size: cover;
    background-position: center;
    transform-origin: center center;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`

export const AboutContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 5rem;

  & > h1 {
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 2.5rem;
    color: #2f4f4f;
    padding: 5px 20px;
  }

  & > p {
    text-align: justify;
    margin-bottom: 1rem;
    color: rgb(69, 69, 69);
    word-spacing: -2px;
    font-size: 1.1rem;
    font-weight: lighter;
    line-height: 2rem;
    margin-bottom: 4.5rem;
  }

  @media (max-width: 768px) {
    margin: 0 2rem;
    & > h1 {
      font-size: 2rem;
    }
    & > p {
      font-size: 1rem;
      letter-spacing: 1px;
      text-align: center;
    }
  }
`

export const LookFor = styled.div`
  & > a {
    text-decoration: none;
    color: #fff;
    border: 1px solid #2f4f4f;
    padding: 12px 34px;
    font-size: 13px;
    background: #2f4f4f;
    position: relative;
    cursor: pointer;
    width: fit-content;
    margin-top: 30px;
    border-radius: 4px;
  }

  & a:hover {
    color: #2f4f4f;
    border: 1px solid #2f4f4f;
    background: transparent;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin: 0 2rem;
    & > a {
      align-items: center;
    }
  }
`

export const Our = styled.div`
  width: 85%;
  margin: auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 3rem;

  & > p {
    padding-top: 2rem;
    font-size: 19px;
    line-height: 30px;
  }

  & h1 {
    color: #2f4f4f;
    font-size: 2rem;
  }
`

export const OurCol = styled.div`
  flex-basis: 31%;
  border-radius: 10px;
  text-align: center;

  & img {
    width: 100%;
    height: 30rem;
    object-fit: cover;

    @media (max-width: 1024px) {
      max-height: 300px;
      height: 300px;
    }
  }

  & p {
    padding: 0;
    color: #93695a;
    font-size: 15px;
    letter-spacing: 1px;
  }

  & h3 {
    font-size: 1.2rem;
    margin-top: 16px;
    margin-bottom: 10px;
    text-align: center;
    color: #2f4f4f;
  }
`
export const Row = styled.div`
  margin-top: 5%;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  @media (max-width: 1024px) {
    flex-direction: column;

    & > * {
      margin-top: 1rem;
    }
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    margin-top: 4%;
    gap: 1.5rem;
  }

  @media (max-width: 768px) {
    margin-top: 3%;
    padding: 1rem;
    gap: 1rem;
  }
`

export const Ruo = styled.div`
  text-align: left;
  margin-top: 6rem;
  padding: 3rem;
  background-color: #fff;

  h1 {
    font-size: 2rem;
    margin-bottom: 3rem;
    color: #2f4f4f;
    text-align: center;
  }

  .service {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;

    &:nth-child(2) {
      flex-direction: row-reverse;
    }

    &-content {
      flex-basis: 50%;
      padding: 0 2rem;

      h2 {
        font-size: 3rem;
        color: #2f4f4f;
        margin-bottom: 1rem;
      }

      h3 {
        font-size: 1.5rem;
        color: #2f4f4f;
        margin-bottom: 1rem;
        text-transform: uppercase;
      }

      p {
        font-size: 1rem;
        line-height: 1.5;
        color: #666;
      }
    }

    &-image {
      flex-basis: 50%;
      padding: 0 2rem;

      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  }

  @media (max-width: 768px) {
    .service {
      flex-direction: column;

      &-content,
      &-image {
        flex-basis: 100%;
        padding: 1rem 0;
      }

      &:nth-child(2) {
        flex-direction: column;
      }

      &-image {
        order: -1;
      }
    }
  }
`
export const ContactSection = styled.div`
  padding: 3rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 2rem;
    margin-top: 4rem;
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    margin-top: 3rem;
  }

  @media (max-width: 480px) {
    padding: 1rem;
    margin-top: 2rem;
  }
`

export const ContactContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;

  .contact-text {
    flex: 1;
    text-align: left;

    & h1 {
      font-size: 1.8rem;
      color: #2f4f4f;
    }

    & p {
      font-size: 1.1rem;
      color: #4a4a4a;
      line-height: 2rem;
    }

    & a {
      text-decoration: none;
    }

    & a:hover {
      text-decoration: underline;
      color: #333;
    }

    @media (max-width: 1024px) {
      text-align: center;

      & h1 {
        font-size: 1.8rem;
      }

      & p {
        font-size: 1rem;
        line-height: 1.8rem;
      }
    }

    @media (max-width: 768px) {
      & h1 {
        font-size: 1.6rem;
      }

      & p {
        font-size: 0.9rem;
      }
    }

    @media (max-width: 480px) {
      & h1 {
        font-size: 1.4rem;
      }

      & p {
        font-size: 0.8rem;
        line-height: 1.5rem;
      }
    }
  }

  .contact-image {
    flex: 0.5;
    text-align: center;

    & img {
      width: 200px;
      height: auto;
      border-radius: 50%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 1024px) {
      margin-top: 2rem;
    }

    @media (max-width: 768px) {
      & img {
        width: 150px; /* Zmniejszamy rozmiar zdjęcia na mniejszych ekranach */
      }
    }

    @media (max-width: 480px) {
      & img {
        width: 120px; /* Dodatkowe zmniejszenie zdjęcia dla małych ekranów */
      }
    }
  }
`
export const StatusContainer = styled.div`
  background-color: #f5f5f5;
  text-align: center;
  padding: 50px 20px;
  font-family: "Arial", sans-serif;
`

export const HeaderInfo = styled.h1`
  font-size: 2.5rem;
  color: #2f4f4f;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`

export const Description = styled.p`
  font-size: 1.1rem;
  color: #666;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto 30px;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.6;
  }
`

export const Highlight = styled.p`
  font-size: 1.3rem;
  color: #333;
  font-weight: bold;
  margin: 30px 0;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 30px;

  img {
    width: 400px;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`

export const ContactButton = styled.button`
  background-color: #2f4f4f;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 4px;

  &:hover {
    background-color: transparent;
    border: solid 1px #2f4f4f;
    color: #2f4f4f;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 10px 25px;
  }
`

export const WhatsAppButton = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
  color: #fff;
  text-decoration: none;

  & path {
    color: white;
  }

  &:hover {
    background-color: #1ebc5a;
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }
`
