import React, { useState } from "react"
import {
  Container,
  Tabs,
  Tab,
  Content,
  ImageSection,
  InfoSection,
  Title,
  Table,
  FloorPlan,
  DownloadButton,
} from "./layoutElements"

import karta1 from "../../assets/karta1.jpg"
import karta2 from "../../assets/karta2.jpg"

const Layout = () => {
  const [activeTab, setActiveTab] = useState("parter")

  const data = {
    parter: {
      title: "Osiedle Millenijne Wzgórze - Parter",
      image: karta1,
      floorPlan: karta1,
      table: [
        { number: 1, name: "Wiatrołap", area: "3.71 m²" },
        { number: 2, name: "Pokój dzienny z aneksem", area: "34.13 m²" },
        { number: 3, name: "Gabinet", area: "6.77 m²" },
        { number: 4, name: "Łazienka", area: "2.44 m²" },
        { number: 5, name: "Komunikacja", area: "1.78 m²" },
      ],
      total: "48.83 m²",
    },
    poddasze: {
      title: "Osiedle Millenijne Wzgórze - Poddasze",
      image: karta2,
      floorPlan: karta2,
      table: [
        { number: 1, name: "Sypialnia 1", area: "13.85 m²" },
        { number: 2, name: "Sypialnia 2", area: "10.45 m²" },
        { number: 3, name: "Sypialnia 3", area: "10.08 m²" },
        { number: 4, name: "Łazienka", area: "6.57 m²" },
        { number: 5, name: "Komunikacja", area: "6.26 m²" },
      ],
      total: "47.21 m²",
    },
  }

  const tabData = data[activeTab]
  const handleDownload = (fileUrl, fileName) => {
    const link = document.createElement("a")
    link.href = fileUrl
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <Title>{tabData.title}</Title>

      <Container id="layout">
        <Tabs>
          <Tab
            active={activeTab === "parter"}
            onClick={() => setActiveTab("parter")}
          >
            Parter
          </Tab>
          <Tab
            active={activeTab === "poddasze"}
            onClick={() => setActiveTab("poddasze")}
          >
            Poddasze
          </Tab>
        </Tabs>
        <Content>
          <ImageSection>
            <img src={tabData.image} alt={`${activeTab} layout`} />
          </ImageSection>
          <InfoSection>
            <Table>
              <thead>
                <tr>
                  <th>Numer</th>
                  <th>Pomieszczenie</th>
                  <th>Pow. podłogi</th>
                </tr>
              </thead>
              <tbody>
                {tabData.table.map((row) => (
                  <tr key={row.number}>
                    <td>{row.number}</td>
                    <td>{row.name}</td>
                    <td>{row.area}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="2">Suma:</td>
                  <td>{tabData.total}</td>
                </tr>
              </tbody>
            </Table>
            <FloorPlan>
              <img src={tabData.floorPlan} alt={`${activeTab} floor plan`} />
            </FloorPlan>
            <DownloadButton
              onClick={() =>
                handleDownload(tabData.image, `${activeTab}-layout.jpg`)
              }
            >
              Pobierz plan
            </DownloadButton>
          </InfoSection>
        </Content>
      </Container>
    </>
  )
}

export default Layout
